import React from "react"
import { graphql, PageProps } from "gatsby"
import { withoutAuthentication } from "@app/hoc/Authentication"
import Page from "@app/components/Wishlist/Wishlist"

export type Props = PageProps<GatsbyTypes.PageWishlistQuery, GatsbyTypes.PageWishlistQueryVariables>

export const query = graphql`
  query PageWishlist {
    page: sanityPageAccountWishlist {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withoutAuthentication(Component)
